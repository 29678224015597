import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'order',
    loadChildren: () => import('./order/order.module').then( m => m.OrderPageModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./map/map.module').then( m => m.MapPageModule)
  },
  {
    path: 'track',
    loadChildren: () => import('./track/track.module').then( m => m.TrackPageModule)
  },
  {
    path: 'recieve',
    loadChildren: () => import('./recieve/recieve.module').then( m => m.RecievePageModule)
  },
  {
    path: 'summary',
    loadChildren: () => import('./summary/summary.module').then( m => m.SummaryPageModule)
  },
  {
    path: 'report-list',
    loadChildren: () => import('./report-list/report-list.module').then( m => m.ReportListPageModule)
  },
  {
    path: 'car-list',
    loadChildren: () => import('./car-list/car-list.module').then( m => m.CarListPageModule)
  },
  {
    path: 'car-dashboard',
    loadChildren: () => import('./car-dashboard/car-dashboard.module').then( m => m.CarDashboardPageModule)
  },
  {
    path: 'add-fuel',
    loadChildren: () => import('./add-fuel/add-fuel.module').then( m => m.AddFuelPageModule)
  },
  {
    path: 'add-accident',
    loadChildren: () => import('./add-accident/add-accident.module').then( m => m.AddAccidentPageModule)
  },
  {
    path: 'add-maintain',
    loadChildren: () => import('./add-maintain/add-maintain.module').then( m => m.AddMaintainPageModule)
  },
  {
    path: 'add-claim',
    loadChildren: () => import('./add-claim/add-claim.module').then( m => m.AddClaimPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
